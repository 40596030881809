<template>
  <div>
    <!-- lead-search -->
    <div
      class="lead-search-area"
      v-if="$storage.get('auth').user.user_type != 2"
    >
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-5">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Agnet</label>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.agent"
                  v-on:change="updateData('agent')"
                >
                  <option value="">Select Agent</option>
                  <option
                    v-for="emp in allEmployees"
                    :key="emp.id"
                    :value="emp.id"
                  >
                    {{ emp.name }}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-5">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Teams</label>

                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.team"
                  v-on:change="updateData('team')"
                >
                  <option value="">Select Team</option>
                  <option
                    v-for="team in allTeams"
                    :key="team.id"
                    :value="team.tm_id"
                  >
                    {{ team.tm_name }}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-2">
          <div class="lead-search">
            <a class="refresh" @click="refresh"
              ><img class="img-fluid" src="assets/img/refresh.svg" alt="" />
              Refresh</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- lead-search -->

    <!-- leads-table-area  -->
    <div class="leads-table-area task-table">
      <div class="timeline">
        <button
          type="button"
          v-bind:class="$store.state.taskFilter == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.taskFilter == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.taskFilter == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Date</span>
        </button>
      </div>

      <table class="display" id="tasks_table">
        <thead>
          <tr>
            <th>Task ID</th>
            <th>Task Date</th>
            <th>Customer Name</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Task Assigned To</th>
            <th>Task Description</th>
            <th>Status</th>
            <th>Action</th>

            <!-- <th>Task Id</th>
            <th>Task Name</th>
            <th>Leadsheet</th>
            <th>Agent</th>
            <th>Date</th>
            <th>Scheduled For</th>
            <th>Status</th> -->
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->
    <!-- appointment  deatils Modal -->
    <div
      class="modal fade"
      id="tasktDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-12">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Description</td>
                        <td v-html="task_detail.a_desc"></td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{{ task_detail.a_date }}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>{{ task_detail.a_time }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled By</td>
                        <td>{{ task_detail.a_by }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled For</td>
                        <td>{{ task_detail.a_for }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{{ task_detail.a_status }}</td>
                      </tr>
                      <tr>
                        <td>Lead Id</td>
                        <td>
                          <a
                            href="#"
                            v-on:click.stop.prevent="
                              redirectIt(task_detail.ta_lead_id)
                            "
                          >
                            {{ task_detail.ta_lead_id }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Created Date</td>
                        <td>{{ task_detail.created_date }}</td>
                      </tr>
                      <br />
                      <div class="row gotolead">
                        <a
                          href="#"
                          v-on:click.stop.prevent="
                            redirectIt(task_detail.ta_lead_id)
                          "
                          class="rem-btn"
                          ><span> Go to lead</span></a
                        >
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- <div class="col-12 text-center mt-3">
                <button class="rem-btn" style="display: initial">Select</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- appointment  deatils Modal  -->
    <!-- update-Tasks -->
    <div class="add-tasks update-task">
      <div class="modal-header">
        <h5 class="modal-title">Edit Task</h5>
        <a
          class="close hide-task"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click.stop.prevent="toggleUpdateTask('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="updateTask">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress2">Task type</label>
            <select
              class="form-control js-example-tags"
              @change="onReschecduleTask"
              v-model="updTask.type"
            >
              <option selected="selected" value="">Choose type</option>
              <option value="1">Outbound call</option>
              <option value="2">Send text</option>
              <option value="3">Send email</option>
              <option value="4">Appt confirmation</option>
              <option value="5">Lead notes</option>
            </select>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.type.$hasError === true"
            >
              {{ $vd.updTask.$errors.type[0] }}
            </div>
          </div>
          <div
            class="form-group alert-con"
            v-if="updTask.type == 2 || updTask.type == 3"
          >
            <div class="table-footer">
              <label for="inputAddress">Automate Option</label>
              <li class="show-delet">
                <label class="switch"
                  ><input
                    type="checkbox"
                    v-model="updTask.automation"
                    @change="onChnageupdateHandelere($event)"
                    true-value="1"
                    false-value="0" /><span class="slider round"></span
                ></label>
              </li>
            </div>
          </div>
          <div class="form-group" v-if="this.sms && updTask.automation == 1">
            <label for="inputAddress2">Select Sms Templates</label>
            <select
              class="form-control js-example-tags"
              v-model="updTask.template"
            >
              <option selected="selected" value="">Choose type</option>
              <option
                :value="template.id"
                v-for="template in sms_templates"
                :key="template.id"
              >
                {{ template.name }}
              </option>
            </select>
          </div>
          <div class="form-group" v-if="this.email && updTask.automation == 1">
            <label for="inputAddress2">Select Email Templates</label>
            <select
              class="form-control js-example-tags"
              v-model="updTask.template"
            >
              <option selected="selected" value="">Choose type</option>
              <option
                :value="template.ut_id"
                v-for="template in email_templates"
                :key="template.ut_id"
              >
                {{ template.ut_title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="updTask.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="updTask.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.updTask.ufDate.$hasError === true"
            >
              {{ $vd.updTask.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Time</label>
              <vue-timepicker
                v-model="updTask.time"
                format="h:mm A"
                name="timepicker"
                :minute-interval="5"
                manual-input
                placeholder="hh:mm A"
              ></vue-timepicker>
             <!-- <vue-timepicker v-model="yourStringValue"  name="timepicker" format="h:mm:ss A"></vue-timepicker> -->
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.time.$hasError === true"
            >
              {{ $vd.updTask.$errors.time[0] }}
            </div>
            <!-- <div class="form-group col-md-4">
                    <label for="inputCity">Time</label>
                    <select class="form-control"  v-model="updTask.tHour">
                        <option value="">hours</option>
                        <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                    </select>
                    </div>
                    <div class="form-group col-md-4">
                    <label for="inputState"></label>
                    <select class="form-control"  v-model="updTask.tMin">
                        <option value="">minutes</option>
                        <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                    </select>
                    </div>
                    <div class="form-group col-md-4">
                    <label for="inputZip"></label>
                    <select class="form-control" v-model="updTask.tType">
                        <option value="am">am</option>
                        <option value="pm">pm</option>
                    </select>
                    </div> -->
          </div>
          <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select class="form-control" v-model="updTask.assignTo">
              <option value="">Select user...</option>
              <option
                v-for="emp in allEmployees"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
            </select>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.assignTo.$hasError === true"
            >
              {{ $vd.updTask.$errors.assignTo[0] }}
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress">Name this task</label>
            <textarea
              class="form-control"
              rows="2"
              v-model="updTask.name"
            ></textarea>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.name.$hasError === true"
            >
              {{ $vd.updTask.$errors.name[0] }}
            </div>
          </div>
          <!-- Current Status -->
          <div class="form-group">
            <label for="inputAddress2">Current Status</label>
            <select class="form-control" v-model="updTask.status">
              <option value="0" :selected="updTask.status == 0">Pending</option>
              <option value="1" :selected="updTask.status == 1">Complete</option>
              <option value="2" :selected="updTask.status == 2">Missed</option>
            </select>
          </div>
        </div>
        <div class="modal-footer app-footer">
          <a
            href="#"
            class="add-btn"
            v-on:click.stop.prevent="toggleUpdateTask('hide')"
          >
            Back</a
          >

          <button :disabled="taskloader" type="submit" class="rem-btn">
            <span v-if="taskloader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!taskloader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Update</span
            >
          </button>
        </div>
      </form>
    </div>
    <!-- update-Tasks -->
  </div>
</template>


<script>
import axios from "axios";
import $ from "jquery";
import VueDaval from "vue-daval";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import DeleteIcon from "../../assets/img/Delete.svg";
import ViewIcon from "../../assets/img/Eye.svg";
import Swal from "sweetalert2";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import EditIcon from "../../assets/img/edit.svg";
export default {
  name: "AllTasks",
  props: ["defaultAssignTo", "employeesList", "lead_status"],
  components: {
    DateRangePicker,
    VueTimepicker,
  },
  mixins: [VueDaval],
  data() {
    return {
      yourStringValue: '3:mm:05 A',
      taskloader: false,
      sms: false,
      email: false,
      sms_templates: [],
      email_templates: [],
      automatdd: false,
      aptColors: ["green-meeting", "red-meeting", "blue-meeting"],
      yesterdayDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      lTasks: [],
      addTasks: [],
      tskDate: { startDate: new Date() },
      tasks: null,
      filterSearch: {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
      },
      task_detail: {},
      allEmployees: [],
      allTeams: [],
      updTask: {
        id: false,
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: "",
        name: "",
        type: "",
        page: this.$route.name,
        template: "",
        automation: "",
        status: 0,
      },
    };
  },
  watch: {
    defaultAssignTo: function (rt) {
      this.updTask.assignTo = rt;
    },
  },
  created() {
    this.getAllEmployees();
    this.getAllTeams();
    let vm = this;
    vm.getTasks();
    vm.getSmsTemplates();
    vm.getTemplates();
    if (
      this.$route.query.duration != undefined &&
      this.$route.query.duration != null &&
      this.$route.query.duration != "undefined" &&
      this.$route.query.duration != ""
    ) {
      this.filterSearch.duration = this.$route.query.duration;
    }
  },
  vdRules: {
    updTask: {
      ufDate: { required: true },
      name: { required: true },
      type: { required: true },
      assignTo: { required: true },
      time: {
        required: true,
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "This time is not valid. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
    },
  },
  methods: {
    refresh() {
      (this.filterSearch = {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
      }),
        $("#tasks_table").dataTable().fnDraw();
    },
    redirectIt(id) {
      $("#tasktDetail").modal("hide");
      setTimeout(() => {
        this.$router.push("/lead-detail/" + id);
      }, 400);
    },
    taskDetail(id) {
      axios
        .get("/task/detail/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.task_detail = data.success;
          }
          $("#tasktDetail").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.allEmployees = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.allTeams = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData(val) {
      if (val == "agent") {
        this.filterSearch.team = "";
      } else if (val == "team") {
        this.filterSearch.agent = "";
      } else if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.$store.state.task_from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.task_to = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.commit("updateTaskFilter", "custom");
      } else {
        this.$store.commit("updateTaskFilter", val);
      }
      $("#tasks_table").dataTable().fnDraw();
    },
    deleteTask(id) {
      axios
        .get("delete_task/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          }
      }).then(()=>{
          $("#tasks_table").dataTable().fnDraw();
      }).catch((err)=>{
          console.log(err);
      });
    },
    onReschecduleTask() {
      if (this.updTask.type == 2) {
        this.sms = true;
        this.email = false;
      } else if (this.updTask.type == 3) {
        this.email = true;
        this.sms = false;
      } else {
        this.email = false;
        this.sms = false;
      }
    },
    getSmsTemplates() {
      axios
        .get("get_smstemplates", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.sms_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.sms_templates.length > 0) {
                vm.temp_ = vm.sms_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTemplates() {
      axios
        .get("get_templates/1", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.email_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.email_templates.length > 0) {
                vm.temp_ = vm.email_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChnageHandelere(event) {
      if (event.target.checked) {
        this.addTask.automation = 1;
        if (this.addTask.type == 2) {
          this.sms = true;
        }
        if (this.addTask.type == 3) {
          this.email = true;
        }
      } else {
        this.addTask.automation = 0;
        this.sms = false;
        this.email = false;
      }
    },
    onChnageupdateHandelere(event) {
      if (event.target.checked) {
        this.updTask.automation = 1;
        if (this.updTask.type == 2) {
          this.sms = true;
        }
        if (this.updTask.type == 3) {
          this.email = true;
        }
      } else {
        this.updTask.automation = 0;
        this.sms = false;
        this.email = false;
      }
    },
    reScheduleTask(id) {
      axios
        .get("/task/get/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data.success) {
            let tsk = data.success;
            this.updTask.id = tsk.ta_id;
            this.updTask.lead_id = tsk.ta_lead_id;
            this.updTask.name = tsk.ta_name;
            this.updTask.assignTo = tsk.ta_created_for;
            this.updTask.type = tsk.ta_task_type;
            this.updTask.ufDate.startDate =  moment(tsk.ta_date).format('MMMM DD, YYYY');
            this.updTask.ufDate.endDate =  moment(tsk.ta_date).format('MMMM DD, YYYY');
            this.updTask.time =  moment(tsk.ta_date).format('h:mm A');
            this.updTask.template = tsk.ta_template;
            this.updTask.automation = tsk.ta_automated;
            this.updTask.status = tsk.ta_status;
            if (tsk.ta_task_type == 2) {
              this.sms = true;
              this.email = false;
            } else if (tsk.ta_task_type == 3) {
              this.email = true;
              this.sms = false;
            } else {
              this.sms = false;
              this.email = false;
            }
            this.toggleUpdateTask("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
        //updateApt: {id:false,date:'',ufDate:{},tHour:'',tMin:'',tType:'am',assignTo:'',name:'',type:'',page:this.$route.name}
        
      
    },
    toggleUpdateTask(action) {
      if (action == "show") {
        $(".update-task").addClass("show-add-tasks");
      } else if (action == "hide") {
        $(".update-task").removeClass("show-add-tasks");
      }
    },
    updateTask() {
      this.taskloader = true;
      if (this.$route.params.id) {
        this.updTask.lead_id = this.$route.params.id;
      }

      this.$vd.updTask
        .$validate()
        .then(() => {
          let startDate = this.updTask.ufDate.startDate;
          this.updTask.date = moment(new Date(startDate)).format("YYYY-MM-DD");
          //console.log(this.updTask);
          axios
            .post("update_task", this.updTask, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.taskloader = false;
                this.toggleUpdateTask("hide");
                this.updTask = {
                  id: false,
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: "",
                  name: "",
                  type: "",
                  page: this.$route.name,
                };
                this.$root.$emit("action", "new_activity");
                // refresh the table
                this.refresh();
              }
            })
            .catch((err) => {
              this.taskloader = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.taskloader = false;
          console.log(this.$vd.updTask.$errors);
        });
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
  // watch: {
  //     "$route.query.type": function () {
  //         this.updateData()
  //     },
  //     "filterSearch.keyword": function() {
  //         this.updateData()
  //     },

  // },
  mounted() {
    let v = this;
    this.$store.commit("resetAllFilters");
    if (
      v.$route.query.duration != undefined &&
      v.$route.query.duration != null &&
      v.$route.query.duration != "undefined" &&
      v.$route.query.duration != ""
    ) {
      v.filterSearch.duration = v.$route.query.duration;
      v.$store.commit('updateTaskFilter',v.$route.query.duration);
    }else{
      v.filterSearch.duration = "month";
      v.$store.commit('updateTaskFilter',"month");
    }

    $("#tasks_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        stateSave: true,
        order: [[1, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "tasks_list",
          type: "POST",
          data: function (d) {
            d.agent = v.filterSearch.agent;
            d.tm_id = v.filterSearch.team;
            d.duration = v.$store.state.taskFilter;
            d.from = v.$store.state.task_from;
            d.to = v.$store.state.task_to;
            d.type = v.$route.query.type;
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        stateSaveParams: function (s, d) {
          console.log(s);
          d.agent = v.filterSearch.agent;
          d.tm_id = v.filterSearch.team;
          d.duration = v.$store.state.taskFilter;
          d.from = v.$store.state.task_from;
          d.to = v.$store.state.task_to;
          d.type = v.$route.query.type;
        },
        stateLoadParams: function (s,d) {
          console.log(s);
          v.filterSearch.agent = d.agent;
          v.filterSearch.team = d.tm_id;
          v.$store.state.taskFilter = d.duration;
          v.$store.state.task_from = d.from;
          v.$store.state.task_to = d.to;
        },
        columnDefs: [
          {
            targets: [3, 4, 5, 6],
            orderable: false,
          },
          {
            targets: 8,
            orderable: false,
            render: function (data, type, row) {
              // console.log({row});
              return (
                '<a href="#" class="task-action" data-id="' +
                row[9] +
                '" data-action="view"><img src="'+ViewIcon+'" /></a> | <a href="#" class="task-action" data-id="' +
                row[8] +
                '" data-action="edit"><img src="'+EditIcon+'" /></a> | <a href="#" class="task-action" data-id="' +
                row[8] +
                '" data-action="delete"><img src="'+DeleteIcon+'" /></a>'
              );
            },
          }
        ],
      });

    $("#tasks_table").on("click", "tr", function () {
      var id = $(this).find(".tskdetail").data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        // v.taskDetail(id);
        // v.redirectIt(id);
      }
    });

    $("#tasks_table").on("click",".task-action", function () {
      //get data-id attribute of the clicked element
      var id = $(this).data("id");
      var action = $(this).data("action");
      
      console.log('id',id);
      console.log('action',action);

      if (id !== undefined && id != "undefined" && id != null && id != "") {
        switch (action) {
          case 'edit':
            v.reScheduleTask(id);
            break;
          case 'view':
            v.redirectIt(id);
            break;
          case 'delete':
              //sweet alert confirmation
              Swal.fire({
                title: "Are you sure?",
                text: "You want to delete this task?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.value) {
                  v.deleteTask(id);
                }
              });
            break;
          default:
            break;
        }
      }
    });

    $("#tasks_table").on("click", ".customer_name", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.redirectIt(id);
      }
    });
  },
};
</script>

<style scoped>
.task-table .timeline,
.addcustomer-table .timeline {
  margin-top: 103px;
  position: relative;
  top: 15px;
  float: right;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  width: 330px;
  margin: 0 auto;
}
.vue-daterange-picker {
  width: 100%;
}
.vue__time-picker input.display-time {
  margin: 0;
  font-size: 14px !important;
  width: 100% !important;
  color: #6d778e;
  border: 1px solid #ccc !important;
  font-weight: 400;
  z-index: 2;
  background: transparent;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem !important;
  height: auto !important;
}
.form-group .vue-daterange-picker {
  width: 100% !important;
}
.tasks-area .vue-daterange-picker {
  width: 100% !important;
}
.vue__time-picker {
  width: 100% !important;
}
.vue__time-picker input.display-time:focus {
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
.vue-daterange-picker {
  display: block !important;
}
.vue__time-picker input.display-time::-webkit-input-placeholder,
.vue__time-picker input.display-time::-ms-input-placeholder,
.vue__time-picker input.display-time::placeholder,
.vue__time-picker input.display-time:-ms-input-placeholder {
  color: #bbb;
}
@import "../../assets/css/dataTables.css";
</style>